.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 90;
  max-height: 80px;
  transition: all 0.5s;
}

.not-fixed {
  position: revert;
}

.navbar-top {
  background: #CBBDBD;
}

.moving-nav {
  background: #574142;
  color: white;
  border-bottom: 1px solid white;
}

#home {
  font-weight: 100;
  font-size: 2rem;
  padding-left: 2.5rem;
}

.moving-nav #home a {
  color: white;
}

.navbar-top #home a {
  color: black;
}

.navbar-top #home a:hover {
  color: #574142;
}

.moving-nav #home a:hover {
  color: #CBBDBD;
}

.moving-nav .quick-link a {
  color: white;
}

.navbar-top .quick-link a {
  color: black;
}

.quick-link a:hover {
  color: #574142;
}

.moving-nav .quick-link a:hover {
  color: #CBBDBD;
} 

.links {
  width: 50%;
  padding: 0 2rem;
  font-size: 1.2rem;
  transition: all 1s;
}

.quick-links {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: space-around;
}


a {
  text-decoration: none;
}

.resume-link {
  background: #574142;
  color: #CBBDBD;
  padding: 2px 20px;
  border-radius: 5px;
}

.resume-link:hover{
  filter: brightness(70%)
}

.resume-link-top{
  background: #574142;
  color: #CBBDBD;
}

.moving-resume-link{
  background: #CBBDBD;
  color: #574142;
}


.hamburger {
  display: none;
  cursor: pointer;
  padding: 0 2rem;
  transition: all 1s;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  background-color: white;
  transition: all 1s;
}

.home {
  height: 95vh;
  width: 100%;
  display: flex;
  background: #CBBDBD;
}

.home-contents {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 45%;
  margin: 0 3%;
  flex-direction: column;
}

.intro h1{
  font-weight: 400;
  font-size: 3rem;
}

.intro p {
  font-size: 2.3rem;
}

.home-icons {
  display: flex; 
  gap: 2%;
}

.home-icon{
  width: 55px;
  height: 55px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s all;
}

.home-icon:hover {
  transform: translateY(-8px);
}

.cat-btn {
  background: #574142;
  color: #CBBDBD;
  display: flex; 
  align-self: flex-start;
  margin-top: 3%;
  padding: 8px 15px;
  font-size: 1.1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.cat-btn a {
  color: #CBBDBD;
}

.cat-btn:hover {
  filter: brightness(70%);
}

.home-icon-a svg:hover path:nth-of-type(1) {
  filter: brightness(80%);
}

.highlights {
  color: #574142;
}

.section-title {
  border-bottom: 2px solid #CBBDBD;
  font-size: 3rem;
}

.footer {
  margin-top: 2rem;
  background: #CBBDBD;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #574142;
  height: 150px;
}

.footer-icons-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.footer-icons {
  display: flex; 
  gap: 25px;
}

.footer-icon{
  width: 35px;
  height: 35px;
  border-radius: 8px;
  cursor: pointer;
  transition: 1s all;
}

.footer-icon:hover {
  filter: brightness(70%);
}

.footer-paragraph p {
  margin: 0 auto;
  text-align: center;
  color: black;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.footer-paragraph hr {
  height: 0px;
  background: #574142;
  border-bottom: 1px solid #574142;
  margin: 0 25% 20px 25%;
}

@media (max-width: 1280px) {
  #home {
    font-size: 1.5rem;
  }

  .links {
    font-size: 1rem;
  }

  .home-contents{
    width: 70%;
  }

  .intro h1{
    font-size: 2.85rem;
  }
  
  .intro p {
    font-size: 1.9rem;
  }

  .footer-paragraph hr{
  margin: 0 15% 20px 15%;
  }
}



@media (max-width: 768px) {
  .hamburger {
      display: block;
  }

  .hamburger.appear .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
  }

  .hamburger.appear .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
  }

  .hamburger.appear .bar:nth-child(2) {
      opacity: 0;
  }

  .links {
    height: 85%;
    position: fixed;
    left: -100%;
    top: 56px;
    flex-direction: column;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: space-evenly;
    z-index: 99;
    padding: 0;
}

.links.appear {
    left: 0;
}

.quick-links {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: inherit;
      gap: 10px;
      background: #574142;
}

#home a{
  color: black;
}

.home-contents {
  width: 100%;
  align-items: center;
}

.intro h1{
  font-size: 3rem;
  text-align: center;
}

.intro p {
  font-size: 1.65rem;
  text-align: center;
}

.home-icons {
  justify-content: center;
  align-items: center;
  gap: 40%;
}

.cat-btn {
  align-self: center;
  padding: 9px 30px; 
}

.home-icon{
  width: 45px;
  height: 45px;
}

.footer {
  height: 130px;
}

}


