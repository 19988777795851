#myWork {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scroll-margin-top: 50px;
}

.highlighted-projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    gap: 10%;
    row-gap: 100px;
}

.project-preview-div{
    background: #CBBDBD;
    color: black;
    display: flex;
    max-width: 650px;
    max-height: 250px;
    flex-basis: 40%;
    border-left: 5px solid #574142;
    cursor: pointer;
    transition: all 1s;
}

.project-preview-div:hover {
    transform: translateY(-10px);
    filter: brightness(70%);
}

.project-preview-logo {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 115px;
    padding: 0.8rem 1.55rem;
}

 .project-preview-logo img{
    width: 95%;
    height: inherit;
    object-fit: contain; 
}

.project-preview-title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}

.project-preview-title h1 {
    font-size: 1.8rem;
}

.project-subTitle {
    display: block;
    font-size: 13px;
    color: #50565e;
    padding-left: 30px;
}

.project-preview-title h1:nth-of-type(2){
    font-weight: 350;
    font-family: 'Ui monospace', sans-serif;
}

.noteworthy-projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 8%;
    row-gap: 20px;
    margin: 1% 5%;
    width: 100%;
}

.noteworthy-project {
    clip-path: polygon(0 0, 30% 0, 37% 10%, 100% 10%, 100% 100%, 0 100%);
    max-width: 380px;
    flex-basis: 100%;
    min-height: 290px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    text-align: center;
    background: #CBBDBD;
    padding-bottom: 10px;
}

.noteworthy-project-summary {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    height: inherit;
}

.noteworthy-project-summary h1 {
    font-size: 2rem;
}

.noteworthy-project-summary-description{
    font-size: 0.9rem;
    margin: 0 20px;
    line-height: 25px;
}

.tech-stack {
    font-size: 0.8rem;
    font-style: italic;
}

.noteworthy-project-btns {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.noteworthy-project-btns a {
    height: 35px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    transition: 0.2s all;
}

.noteworthy-project-btns a:nth-of-type(1){
    background: #574142;
    color: white;
    border: none;
}

.noteworthy-project-btns a:nth-of-type(1):hover {
    filter: brightness(80%);
}

.noteworthy-project-btns a:nth-of-type(2){
    background: transparent;
    color: #574142;
    border: 1px solid #574142;
    filter: brightness(80%);
}

.noteworthy-project-btns a:nth-of-type(2):hover {
    background: #574142;
    color: white;
}

.noteworthy-title{
    border-bottom: 2px solid #CBBDBD;
    font-size: 1.8rem;
    margin-top: 2em;
}

@media (max-width: 1280px){
    #myWork {
    scroll-margin-top: 70px;
    }
    .project-preview-title h1 {
        font-size: 1.5rem;
    }
b   
    .project-preview-logo{
        padding: 0.5rem 1.55rem;
    }

    .noteworthy-project-summary h1 {
        font-size: 1.5rem;
        color: #574142;
    }

    .noteworthy-project {
        max-width: 350px;
        flex-basis: 95%;
    }

    .noteworthy-projects{ 
        gap: 5%;
        row-gap: 20px;
    }
}

@media (max-width: 768px){
    .project-preview-div{
        flex-basis: 95%;
    }
}