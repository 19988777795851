body, html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}



* {
  font-family: 'IBM Plex Serif', serif;
}

.no-scroll {
  overflow: hidden;
}

.scroll {
  overflow: revert;
}

#modalRoot {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  transition: 300ms all;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scale-0 {
  transform: scale(0);
}

.scale-1 {
  transform: scale(1);
}